import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Box,
  Card,
  Grid,
  Tooltip,
  // Input,
  Button,
} from "@mui/material";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import styled from "styled-components";
import moment from "moment";
import { useForm } from "react-hook-form";
import ProposalFilePicker from "../../modules/Dynamic-Claim/ProposalFilePicker";
import { useUpdateClaimStageHistory } from "../../modules/Activity Logs/service";
import Input from "../form-components/input/input";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Edit } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { resetAllData } from "../../modules/Dynamic-Claim/proposal.slice";

const CommunicationLog = ({ data, key, index, popup }) => {
  const [files, setFiles] = useState([]);
  const [showForm, setShowForm] = useState(null);
  const { handleSubmit, watch, register, control, setValue, trigger } = useForm(
    {
      shouldUnregister: true,
    }
  );

  const { uploadedFileURL } = useSelector((state) => state.proposalConfig);

  useEffect(() => {
    return () => {
      setShowForm(null);
    };
  }, []);

  const { data: updateHistoryData, mutate: updateClaimStageHistory } =
    useUpdateClaimStageHistory();

  const dispatch = useDispatch();
  const documents = (data || []).map((item) => [
    ...(item?.missing_doc ?? []),
    ...(item?.additional_doc ?? []),
  ]);

  const onSubmit = (data, queryData) => {
    const docPayload = data?.docIssue?.map((item, index) => {
      // const docName =
      //   (item && item?.document && Object.keys(item?.document)?.[0]) ||
      //   Object.keys(item["comment"])[0]?.split("comment_")[1]; // Getting doc name from comment if file not uploaded for additional docs

      // const docLabel =
      //   documents?.[documents?.length - 1]?.[index]?.doc_label || docName;
      // const docPath = uploadedFileURL[`docIssue[${index}].document.${docName}`];
      // const comment = Object.values(item?.comment)?.[0];

      const docName = item?.documentKey || "";
      const docLabel = item?.documentLabel || docName;
      const docPath = uploadedFileURL[`docIssue.[${index}].path`] || "";
      const comment = item?.comment || "";

      return {
        doc_label: docLabel,
        doc_name: docName,
        doc_path: docPath,
        comment: comment,
        doc_button: "Approve",
      };
    });
    const payload = {
      claimDataRefId: queryData?.claim_data_ref_id || "",
      claimStage: queryData?.claimStatus || "",
      remark: queryData?.remark || "",
      subStatus: queryData?.subStatus || "",
      ...(!_.isEmpty(data?.docIssue) && { missingdocuments: docPayload }),
    };
    updateClaimStageHistory(payload);
    setShowForm(null);
    dispatch(resetAllData());
  };

  const getStatus = (claimStatus, policyNo) => {
    switch (claimStatus?.trim()) {
      case "Intimated":
        return `Your Claim for Policy Number ${policyNo} has been intimated`;
      case "In Progress":
        return `Your Claim for Policy Number ${policyNo} is In Progress`;
      case "In Process":
        return `Your Claim for Policy Number ${policyNo} is In Process`;
      case "Pending":
        return `Your Claim for Policy Number ${policyNo} is in Pending`;
      case "Approved":
        return `Your Claim for Policy Number ${policyNo} has been approved`;
      case "Rejected":
        return `Your Claim for Policy Number ${policyNo} has been rejected`;
      case "Out Standing":
        return `Your Claim for Policy Number ${policyNo} is Out Standing`;
      case "AL issued":
        return `AL issued for a Claim for Policy Number ${policyNo}`;
      case "Query Raised":
        return `Query Raised for a Claim for Policy Number ${policyNo}`;
      case "Query Resolved":
        return `Query Resolved for a Claim for Policy Number ${policyNo}`;
      default:
        return `Your Claim for Policy Number ${policyNo} is ${claimStatus}`;
    }
  };

  const initials = (item) => {
    if (!item) return "";

    const names = item.split(" ");
    return names
      .map((word, index) => {
        return index === 0 || index === names.length - 1 ? word[0] : "";
      })
      .join("");
  };

  return (
    <>
      <div style={{ padding: popup ? "15px 25px 15px" : "0px" }}>
        {data?.map((item, index) => (
          <CommunicationLogItem key={index}>
            <CommunicationLogTimelineSeparator>
              <CommunicationLogCheckCircleIcon />
              {index < data.length - 1 && <CommunicationLogTimelineConnector />}
            </CommunicationLogTimelineSeparator>
            <CommunicationLogTimelineContent>
              <CommunicationLogPaper>
                <CommunicationLogTypography>
                  {getStatus(item?.claimStatus || item?.stage, item?.policyNo)}
                </CommunicationLogTypography>
                <CommunicationLogTimelineOppositeContent>
                  <p
                    style={{
                      paddingBottom: "5px",
                      margin: 0,
                      textAlign: "left",
                      fontWeight: 700,
                    }}
                  >
                    {item?.heading}
                  </p>
                  <CommunicationLogTypography
                    color="textSecondary"
                    normal={true}
                  >
                    {item?.updated_at || item?.createdAt}
                  </CommunicationLogTypography>
                </CommunicationLogTimelineOppositeContent>
                {(item?.claimStatus || item?.stage) && (
                  <CommunicationLogTimelineStage
                    stage={item?.claimStatus || item?.stage}
                  >
                    <CommunicationLogCircleIcon />
                    {item?.claimStatus || item?.stage}
                  </CommunicationLogTimelineStage>
                )}
                {
                  // item?.remark?.length > 0 &&
                  item?.claimStatus !== "Intimated" && (
                    <FlexBox mt={2} gap={0.5}>
                      <div>
                        <NameBox>
                          <Tooltip
                            title={
                              item?.editDoc === "N"
                                ? `${item?.customer_name} (You)`
                                : item?.broker_name || item?.agentName
                            }
                            arrow
                            style={{ cursor: "pointer" }}
                          >
                            {initials(
                              item?.editDoc === "N"
                                ? item?.customer_name
                                : item?.broker_name || item?.agentName
                            )}
                          </Tooltip>
                        </NameBox>
                      </div>
                      {item?.remark && (
                        <MessageCard>{item?.remark}</MessageCard>
                      )}
                    </FlexBox>
                  )
                }
              </CommunicationLogPaper>
              {item?.claimStatus === "Query Raised" &&
                documents[index]?.length > 0 && (
                  <div>
                    <span style={{}}>Document Issues</span>
                    {/* {showForm && ( */}
                    {Array.isArray(documents) &&
                      documents.length > 0 &&
                      item?.editDoc === "Y" && (
                        // !documents[index]?.filter((item) => item?.doc_path)
                        //   ?.length &&
                        <Button
                          variant="contained"
                          type="button"
                          onClick={() => setShowForm(index)}
                          style={{ float: "right" }}
                        >
                          Add
                        </Button>
                      )}
                    <ul style={{ margin: "0px", padding: "0px" }}>
                      {documents[index] &&
                        documents[index]?.map((doc, docIndex) => {
                          console.log({ doc });
                          return (
                            <>
                              <Grid item xs={12} sm={12} md={3} lg={4}>
                                <li style={{ width: "max-content" }}>
                                  <a
                                    href={doc?.doc_path}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      textDecoration: "none",
                                      // color: "",
                                      width: "100%",
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        doc?.doc_path
                                          ? "View Document"
                                          : "No Document"
                                      }
                                      arrow
                                      placement="top"
                                    >
                                      <sapn
                                        style={{
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {doc?.doc_label || doc?.doc_name}
                                      </sapn>
                                    </Tooltip>
                                  </a>{" "}
                                  - {doc?.comment}
                                </li>
                              </Grid>
                              {showForm === index && item?.editDoc === "Y" && (
                                // !doc?.doc_path &&
                                <>
                                  <Grid
                                    container
                                    columnSpacing={2}
                                    sx={{ marginTop: "10px" }}
                                  >
                                    <input
                                      type="hidden"
                                      value={doc?.doc_name}
                                      {...register(
                                        `docIssue.[${docIndex}].documentKey`
                                      )}
                                    />
                                    <input
                                      type="hidden"
                                      value={doc?.doc_label}
                                      {...register(
                                        `docIssue.[${docIndex}].documentLabel`
                                      )}
                                    />
                                    <Grid item xs={12} sm={12} md={3} lg={4}>
                                      <Input
                                        themeType={"theme1"}
                                        label="Comment"
                                        name={`docIssue.[${docIndex}].comment`}
                                        id={`docIssue.[${docIndex}].comment`}
                                        maxLength="100"
                                        placeholder={"Enter comment"}
                                        autoComplete="none"
                                        defaultValue={""}
                                        required={false}
                                        inputRef={register(
                                          `docIssue.[${docIndex}].comment`
                                        )}
                                        testId={`docIssue.[${docIndex}].comment`}
                                        watch={watch}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={4}>
                                      <ProposalFilePicker
                                        label={`${
                                          doc?.doc_label || doc?.doc_name
                                        }`}
                                        id={`docIssue.[${docIndex}].path`}
                                        files={files}
                                        setFiles={setFiles}
                                        setValue={setValue}
                                        trigger={trigger}
                                      />
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </>
                          );
                        })}
                    </ul>
                    {showForm === index &&
                      documents[index]?.length &&
                      item?.editDoc === "Y" &&
                      files?.length > 0 && (
                        <Button
                          variant="contained"
                          type="button"
                          onClick={() => onSubmit(watch(), item)}
                          style={{ float: "right" }}
                          // disabled={!Object.values(uploadedFileURL)?.map((item) => item)} // Used this, If you want to enable submit button when user upload specific document
                        >
                          Submit
                        </Button>
                      )}
                  </div>
                )}
            </CommunicationLogTimelineContent>
          </CommunicationLogItem>
        ))}
      </div>
    </>
  );
};

export default CommunicationLog;

const StyledMainDiv = styled.div`
  border-radius: 10px;
  padding: 10px;
`;

const CommunicationLogItem = styled(TimelineItem)`
  min-height: 110px !important;
  &:before {
    content: "";
    display: none;
  }
`;

const CommunicationLogTimelineSeparator = styled(TimelineSeparator)`
  display: flex;
  align-items: center;
`;

const CommunicationLogCheckCircleIcon = styled(CheckCircleIcon)`
  width: 30px !important;
  height: 30px !important;
  fill: #1c71ff !important;
`;

const CommunicationLogTimelineConnector = styled(TimelineConnector)`
  background-color: #1c71ff !important;
`;

const CommunicationLogTimelineContent = styled(TimelineContent)`
  padding-top: 0;
`;

const CommunicationLogPaper = styled(Paper)`
  box-shadow: none !important;
`;

const CommunicationLogTypography = styled(Typography)`
  font-size: 13px !important;
  font-weight: ${({ normal }) => (normal ? "400" : "700")} !important;
  line-height: 15.73px !important;
  color: #444444 !important;
  text-align: left;
`;

const CommunicationLogTimelineOppositeContent = styled(TimelineOppositeContent)`
  padding: 0px 0 6px !important;
`;

const CommunicationLogTimelineStage = styled.div`
  display: flex;
  align-items: center;
  max-width: fit-content;
  border-radius: 4px;
  padding: 3px 5px;
  color: ${({ stage }) =>
    stage === "Intimated"
      ? "#14804A"
      : stage === "In Progress"
      ? "#5E5ADB"
      : stage === "Pending"
      ? "#AA5B00"
      : "#5e5adb"};
  background-color: ${({ stage }) =>
    stage === "Intimated"
      ? "#E1FCEF"
      : stage === "In Progress"
      ? "#EDEDFC"
      : stage === "Pending"
      ? "#FCF2E6"
      : "#ededfc"};
  font-size: 12px;
`;

const CommunicationLogCircleIcon = styled(CircleIcon)`
  width: 9px !important;
  height: 9px !important;
  margin-right: 5px;
`;

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  border: ${({ border }) => (border ? `1px solid ${border}` : "")} !important;
  background-color: ${({ bg }) => (bg ? bg : "")} !important;
`;

export const NameBox = styled(Box)`
  width: 30px;
  height: 30px;
  // aspect-ratio: 1;
  border-radius: 50%;
  background-color: #14804a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e1fcef;
  font-size: 12px;
`;

export const MessageCard = styled(Box)`
  padding: 10px;
  border-radius: 7px;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  // border: 1px solid red;
  // background-color: #f2f2f2 !important;
`;
export const DocIssues = styled(Box)`
  padding: 10px;
  border-radius: 7px;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  // border: 1px solid red;
  margintop: 30px;
  // gap: 20px;
  // background-color: #f2f2f2 !important;
`;
