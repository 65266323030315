import { stringify } from "qs";
import SecureLS from "secure-ls";
import axios from "axios";

const defaultOptions = {
  headers: {},
  queryParams: null,
};

export const restClient = axios.create();

restClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const err = error?.response;
    const ls = new SecureLS();
    console.warn({ error: err });

    // if (error.code === "ERR_NETWORK") {
    //   console.warn("Network Error - Treating as 500 status");

    //   if (window.location.pathname !== "/500") {
    //     window.history.pushState(
    //       {
    //         prevUrl: window.location.href,
    //         pathName: window.location.pathname,
    //         search: window.location.search,
    //       },
    //       "",
    //       "/500"
    //     );

    //     window.location.assign("/500");
    //   }
    // }

    if (err?.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      ls.remove("tokenID");
      window.location.href = "/";
    }

    if (err?.status > 500 && err?.status <= 599) {
      if (window.location.pathname !== "/500") {
        window.history.pushState(
          {
            prevUrl: window.location.href,
            pathName: window.location.pathname,
            search: window.location.search,
          },
          "", // Title (optional)
          "/500" // New URL
        );

        window.location.assign("/500");
      }

      // window.location.href = '/500'
    }
    return Promise.reject(error);
  }
);

const httpClient = async (
  url = "",
  options = defaultOptions,
  signal,
  noBaseUrl,
  cancelToken,
  allowRaw,
  payloadKey,
  timeout
) => {
  const { responseType = "json" } = options;
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  let fullPath = noBaseUrl ? `${url}` : `${baseUrl}${url}`;

  if (options.queryParams) {
    const queryString = stringify(options.queryParams);
    fullPath = `${fullPath}?${queryString}`;
  }

  let tokenID =
    (sessionStorage.getItem("token") && sessionStorage.getItem("token")) ||
    (sessionStorage.getItem("byPassedToken") &&
      sessionStorage.getItem("byPassedToken"));

  if (tokenID?.length > 25) {
    restClient.defaults.headers.common["token"] = tokenID;
  }

  if (options.data === "FormData" || options.data?.formData === "FormData") {
    restClient.defaults.headers.common["Content-Type"] = "multipart/form-data";
  }

  return await restClient({
    url: fullPath,
    method: options.method || "GET",
    signal,
    data: options.data,
    cancelToken: options.cancelToken,
    timeout: timeout,
    responseType: responseType,
  })
    .then((response) => {
      return {
        data: response?.data || {},
        error: response?.data.error || response?.data.message,
        alertType: response?.data?.errortype || "error",
        message: response?.data.message,
        success:
          (response?.status === 200 || response?.status === 201) &&
          response?.data?.status,
        ...(allowRaw && { raw_response: response }),
      };
    })
    .catch((err) => {
      throw err;
    });
};

export default httpClient;
