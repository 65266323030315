import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Skeleton,
  Tab,
  Tooltip,
} from "@mui/material";
import styled from "styled-components";
import {
  Close,
  ExpandMore,
  PreviewOutlined,
  FileDownloadRounded,
} from "@mui/icons-material";
import { ActionContainer } from "../../../styles/dataGrid.styles";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useMemo, useState } from "react";
import InlineLoader from "../../Dynamic-Claim/InlineLoader";
import { useGetPaymentLogs, useGetPaymentLogsForCustomer } from "../service";
import { useSelector } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PaymentLogsSkeleton from "./PaymentLogSkeleton";
import PaymentLogsCard from "./PaymentLogsCard";

const PaymentLogs = ({ setOpen, paymentLogsData, isLoadingLogs }) => {
  const [expanded, setExpanded] = useState("panel1");
  const [policyExpanded, setPolicyExpanded] = useState("");
  const [logsExpanded, setLogsExpanded] = useState("");
  const [value, setValue] = useState(0);

  const {
    mutate: policyPaymentMutate,
    data: policyPaymentData,
    isLoading: paymentLoading,
  } = useGetPaymentLogs();

  const handleYearChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePolicyChange = (panel) => (event, isExpanded) => {
    setPolicyExpanded(isExpanded ? panel : false);
  };

  const handleLogChange = (panel, logId) => (event, isExpanded) => {
    setLogsExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      policyPaymentMutate(logId); // Only trigger when expanded
    }
  };

  const { theme } = useSelector((state) => state.theme);
  const paymentLogs = paymentLogsData?.data?.data;
  console.log({ paymentLogs });

  const extractUniqueValues = (logs, key) => {
    if (!Array.isArray(logs)) return [];
    return [...new Set(logs.map((item) => item?.[key]))];
  };

  const tabs = useMemo(
    () => extractUniqueValues(paymentLogs, "lobName"),
    [paymentLogs]
  );

  const userPolicies =
    Array.isArray(paymentLogs) && !!paymentLogs?.length
      ? paymentLogs?.map((item) => item?.policyNumber)
      : [];
  const userPoliciesToDisplay = Array.from(new Set(userPolicies));
  const paymentYear =
    Array.isArray(paymentLogs) && !!paymentLogs?.length
      ? paymentLogs?.map((item) => item?.year && item.year.toString())
      : [];
  const yearsToDisplay = Array.from(new Set(paymentYear));

  return (
    <LogsContainer>
      <Header>
        <Heading theme={theme}>Payment Logs</Heading>
        <IconButton size="medium" onClick={() => setOpen(false)}>
          <Close fontSize="inherit" />
        </IconButton>
      </Header>
      <AccordionContainer>
        {isLoadingLogs && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: "700",
              minHeight: "100%",
            }}
          >
            Payment Logs Loading...
            <InlineLoader />
          </Box>
        )}
        {!!paymentLogs?.length ? (
          yearsToDisplay?.map((year, yearIndex) => {
            return (
              <Accordion
                key={year}
                expanded={expanded === `panel${yearIndex + 1}`}
                onChange={handleYearChange(`panel${yearIndex + 1}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`panel${yearIndex + 1}-content`}
                  id={`panel${yearIndex + 1}-header`}
                >
                  <LogHeading theme={theme}>
                    Payment Details for Year - {year}
                  </LogHeading>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      width: "100%",
                      typography: "body1",
                      "&.MuiBox-root": {
                        marginBottom: "20px",
                      },
                    }}
                  >
                    {userPoliciesToDisplay.map((policyNumber, policyIndex) => {
                      const policyWiseLogs = Array.isArray(paymentLogs)
                        ? paymentLogs?.filter((log) => {
                            return (
                              log?.year?.toString() === year &&
                              log?.policyNumber === policyNumber
                            );
                          })
                        : [];
                      const uniquePoliciesToDisplay = extractUniqueValues(
                        policyWiseLogs,
                        "policyNumber"
                      );
                      const policiesToDisplay = uniquePoliciesToDisplay?.map(
                        (uniquePolicy, ix) => {
                          return (
                            <Accordion
                              key={uniquePolicy}
                              expanded={
                                policyExpanded ===
                                `policyPanel${policyIndex + 1}`
                              }
                              onChange={handlePolicyChange(
                                `policyPanel${policyIndex + 1}`
                              )}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={`policyPanel${ix + 1}-content`}
                                id={`policyPanel${ix + 1}-header`}
                              >
                                {
                                  <LogHeading theme={theme}>
                                    Policy Number:
                                    <span
                                      style={{
                                        fontWeight: 400,
                                        marginLeft: "3px",
                                      }}
                                    >
                                      {uniquePolicy}
                                    </span>
                                  </LogHeading>
                                }
                              </AccordionSummary>
                              <AccordionDetails>
                                {policyWiseLogs?.length > 0 ? (
                                  policyWiseLogs?.map((log) => (
                                    <Accordion
                                      key={log?.recurringPaymentLogId}
                                      expanded={
                                        logsExpanded ===
                                        `logsPanel${log?.recurringPaymentLogId}`
                                      }
                                      onChange={handleLogChange(
                                        `logsPanel${log?.recurringPaymentLogId}`,
                                        log?.recurringPaymentLogId
                                      )}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`logsPanel${log?.recurringPaymentLogId}-content`}
                                        id={`logsPanel${log?.recurringPaymentLogId}-header`}
                                      >
                                        <LogHeading theme={theme}>
                                          <span
                                            style={{
                                              fontWeight: 400,
                                              marginRight: "3px",
                                            }}
                                          >
                                            Month:
                                          </span>{" "}
                                          {log?.month} {log?.year}
                                        </LogHeading>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        {paymentLoading ? (
                                          <PaymentLogsSkeleton />
                                        ) : (
                                          <PaymentLogsCard
                                            theme={theme}
                                            paymentLogData={
                                              policyPaymentData?.data?.data
                                            }
                                          />
                                        )}
                                      </AccordionDetails>
                                    </Accordion>
                                  ))
                                ) : (
                                  <NoLogs>
                                    No Logs Available for this Policy
                                  </NoLogs>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          );
                        }
                      );
                      return policiesToDisplay;
                    })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <NoLogs>No Payment Logs Available</NoLogs>
        )}
      </AccordionContainer>
    </LogsContainer>
  );
};

export default PaymentLogs;

const LogsContainer = styled.div`
  width: 75vw;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
`;

const Heading = styled.h2`
  margin: 0;
  display: flex;
  color: #011340;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
`;

const LogHeading = styled.h3`
  margin: 0 0 0 10px;
  display: flex;
  color: #011340;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`;

const AccordionContainer = styled.div`
  width: 100%;
  height: 93%;
  overflow-y: auto;
  border-radius: 10px;
  border: 0.75px solid #e4e4e4;
`;

const NoLogs = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
`;

const DetailsContainer = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 0.75px solid #e4e4e4;
  border-radius: 10px;
`;

const TotalLog = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 20px 10px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

const LogCount = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-left: 3px;
`;
